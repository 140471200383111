import { render, staticRenderFns } from "./About.vue?vue&type=template&id=c162d0dc&scoped=true&"
import script from "./About.vue?vue&type=script&lang=js&"
export * from "./About.vue?vue&type=script&lang=js&"
import style0 from "./About.vue?vue&type=style&index=0&id=c162d0dc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_svgbhp6mq6cpqeg674bwlepigi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c162d0dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_jyn3ljfz5khtam3igvcs7jnv2i/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAvatar,VBtn,VCard,VCardText,VIcon})
